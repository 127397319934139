import {
  CloudOpsProjectsType,
  FormType,
  ProjectInfoType,
} from 'pages/CompanyPage/types';

export const invoiceInitialDetails: FormType = {
  minimum_monthly_budget: '0',
  monthly_budget_in_hours: 0,
  over_budget_hourly_rate: '0',
};

export const projectInitialData: CloudOpsProjectsType = {
  id: 0,
  id_str: '',
  key: '',
  type: '',
  name: '',
  status: '',
  description: '',
  company_name: '',
  company_hs_id: 0,
  zstream_project_url: '',
  // Quickbooks fields
  quickbook_project_id: '',
  quickbook_project_name: '',
  parent_company_quickbook_id: '',
  // Invoice details fields
  minimum_monthly_budget: '',
  monthly_budget_in_hours: '',
  over_budget_hourly_rate: '',
  // Custom field
  info: { prefix: '-' },
  customType: '',
};

// checks if project's invoice details empty
export const checkIsProjectInvoiceDetailsEmpty = (
  prj: CloudOpsProjectsType
): boolean => {
  const {
    monthly_budget_in_hours,
    minimum_monthly_budget,
    over_budget_hourly_rate,
  } = prj;

  return !(
    !monthly_budget_in_hours ||
    !minimum_monthly_budget ||
    !over_budget_hourly_rate
  );
};

export const getProjectInfo = (prj: CloudOpsProjectsType): ProjectInfoType => {
  // if project is added from zstream via add new project button
  if (prj.company_hs_id && !prj.quickbook_project_id) {
    // if (!checkIsProjectInvoiceDetailsEmpty(prj)) { return {}; }
    return { prefix: '' };
  }

  // if project is caught from Quickbooks
  if (!prj.company_hs_id && prj.quickbook_project_id) {
    return { prefix: '', suffix: 'No CloudOps project synced' };
  }

  // if project has valid invoice details and project is synced with Stream and Quickbooks
  if (
    prj.company_hs_id &&
    prj.quickbook_project_id
    // && checkIsProjectInvoiceDetailsEmpty(prj)
  ) {
    return { prefix: '' };
  }

  // if project has at least one empty invoice details field and project is synced with Stream and Quickbooks
  // if (
  //   prj.company_hs_id &&
  //   prj.quickbook_project_id &&
  //   !checkIsProjectInvoiceDetailsEmpty(prj)
  // ) {
  //   return {};
  // }

  return { prefix: '-' };
};

export const prepareProjectStructure = (prj: CloudOpsProjectsType) => {
  const info = getProjectInfo(prj);
  const customType = prj.id ? 'CloudOps' : 'QuickBooks';
  return { ...prj, customType, info };
};
